<style scoped>
    .label-container > span {
        margin-left: 2px;
    }
</style>

<template>
    <span class="label-container">
        {{ contact.full_name }}
        <template v-if="cie !== undefined && cie.id !== this.defaultCie">
            <span v-if="cie.bc_contact_id === contact.id">(BC)</span>
            <span v-if="cie.pf_contact_id === contact.id">(PF)</span>
            <span v-if="cie.representative_contact_id === contact.id">(REP)</span>
        </template>
    </span>
</template>

<script>

    export default {
        name: "ContactLabel",
        props: {
            contact: Object,
            cie: Object,
        },
        data() {
            return {}
        },
        computed: {
            defaultCie() {
                return parseInt(this.$store.constantStore.getConstantByVariable('NO_CIE_DEFAULT').value);
            },
        },
        methods: {},
        watch: {},
    }
</script>
