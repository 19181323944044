import {defineStore} from "pinia";

import AxiosClass from "@/classes/axios.js";
const axios = new AxiosClass();

export const useEnumsStore = defineStore('enums', {
    state: () => ({
        AddressType: [],
        ApprovalListType: [],
        BillType: [],
        CieType: [],
        EntryStatus: [],
        MaterialOperationType: [],
        ProjectType: [],
        RequisitionStatus: [],
        RequisitionType: [],
        TimeBankType: [],
        WageType: [],
        Tax: [],
        Language: [],
        InOutEvent: [],
        SupervisordStatus: [],
        Currency: [],
        Task: []
    }),
    actions: {
        async loadAllEnums() {
            await axios.get('AllEnums').then((response) => {
                Object.keys(response).map(enumName => {
                    this[enumName] = Object.freeze(response[enumName]);
                });
            });
        },
        findInEnum(obj, searchKey, searchValue) {
            return (Object.entries(obj).find(([_, value]) => value[searchKey] === searchValue) || [])[1];
        },
        getProjectTypeBySection() {
            return [
                {group: Object.values(this.ProjectType).filter(type => type.section === 1)},
                {group: Object.values(this.ProjectType).filter(type => type.section === 2)}
            ];
        },
        reloadEnums() {
            this.$reset();
            this.loadAllEnums();
        },
        clear() {
            this.$reset();
        },
    },
    persist: {
        storage: sessionStorage,
        paths: [
            'AddressType', 'ApprovalListType', 'BillType', 'CieType', 'EntryStatus', 'MaterialOperationType', 
            'ProjectType', 'RequisitionStatus', 'RequisitionType', 'TimeBankType', 'WageType', 'Tax', 
            'Language', 'InOutEvent', 'SupervisordStatus', 'Currency', 'Task'
        ],
    },
});