<style scoped>
    
</style>

<template>
    <div>
        <ModalComponent ref="modal" title="Mot de passe" size="modal-xs"  :can-reset="true"  >
            <div class="container">
                <div class="row">
                    <div class="col-md-12"> 
                        <div class="input-group input-group-sm mb-1">
                            <span class="input-group-text" id="OldPassword">*Ancien:</span>
                            <input type="password" class="form-control" required v-model="oldPassword" 
                                :class="!isPasswordSame? 'is-invalid':''" @change="setDataChanged(true)" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12"> 
                        <div class="input-group input-group-sm mb-1">
                            <span class="input-group-text" id="NewPassword">*Nouveau:</span>
                            <input type="password" class="form-control" required v-model="newPassword" 
                                :class="!isPasswordValid? 'is-invalid':''" @change="setDataChanged(true)" key=""/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12"> 
                        <div class="input-group input-group-sm mb-1">
                            <span class="input-group-text" id="ConfirmPassword">*Confirmer:</span>
                            <input type="password" class="form-control" required v-model="confirmPassword" 
                                :class="!isPasswordValid? 'is-invalid':''" @change="setDataChanged(true)" />
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent>
        <ToastComponent ref="toast"></ToastComponent>
    </div>
</template>

<script>
import ModalComponent from "@/components/_shared/ModalComponent.vue";
import ToastComponent from "@/components/_shared/ToastComponent.vue";

export default {
    name: "PasswordReset",
    components: { ToastComponent, ModalComponent},
    props: {},
    data() {
        return {
            dataChanged: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        }
    },
    computed: {
        isPasswordValid() {
            if (this.oldPassword === "") {
                return false;
            }

            if (this.newPassword <=0 && this.confirmPassword <=0) {
                this.dataChanged=false;
                return false
            }

            if (this.newPassword !== this.confirmPassword) {
                this.dataChanged=false;
                return false;                
            }

            if (this.newPassword === this.oldPassword) {
                return false;
            }

            this.dataChanged=true;
            return true;      
        },
        isPasswordSame() {
            if (this.newPassword === this.oldPassword) {
                return false;
            }
            this.dataChanged=true;
            return true;      
        },
    },
    methods: {
        save() {
            if (!this.isPasswordValid) {
                return;
            }
            this.$axios.post('/PasswordChange',{
                id: this.$store.authUserStore.fullUserDetails.id,
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                newPassword_confirmation: this.confirmPassword
            }).then((response) => {
                if (response) {
                    this.$refs.toast.setEverything('Mot de passe','','Votre mot de passe a été mis à jour.','save');
                    this.reset();
                    this.$refs.modal.close();
                } else {
                    this.$refs.toast.setEverything('Mot de passe','','Assurez-vous d\'entrer le bon mot de passe.','error');
                }
                this.$refs.toast.show();
            });
        },
        open() {
            this.$refs.modal.open();
        },
        reset() {
            this.oldPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
            this.dataChanged = false;
        },
        setDataChanged(isChanged) {
            this.dataChanged = (isChanged && this.isPasswordValid)
        },
    },
    watch: {},
}
</script>