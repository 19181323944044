import { defineStore } from "pinia";
import { usePermissionStore } from "@/store/perms.js";
import { useRememberStore } from "@/store/remember.js";
import AxiosClass from "../classes/axios.js";
import RedisService from '@/classes/redisService.js';
import router from '@/router/index.js';

const axios = new AxiosClass();

export const useAuthUserStore = defineStore('authUser', {
    state: () => ({
        userInfo: null,
        maintenance: {},
        maintenanceToday: {},
        maintenanceInterval: null,
        isThrottled: false,
        currentRoute: null,
        redisService: null,
        removeServiceTimeout: null,

        timeout: null,
        warningTimeout: null,
        inactivityTimer: null,
        warningTimer: null,
        showWarningPopup: false,
    }),
    getters: {
        isLoggedIn: (state) => state.userInfo !== null,
        fullUserDetails: (state) => {
            return { ...state.userInfo?.user }
        },
        isUserDev: (state) => {
            return state.userInfo?.user.is_dev;
        },
        isMaintenance: (state) => {
            return Object.keys(state.maintenance !== undefined ? state.maintenance : {}).length > 0;
        },
        isMaintenanceToday: (state) => {
            return Object.keys(state.maintenanceToday !== undefined ? state.maintenanceToday : {}).length > 0;
        }
    },
    actions: {
        async login(credentials) {
            const rememberStore = useRememberStore();
            if (this.userInfo !== null) throw new Error('Vous êtes déjà connecté');
            try {
                await axios.get(`${import.meta.env.VITE_API_SECURITY || 'http'}://${import.meta.env.VITE_API_ENDPOINT}:${import.meta.env.VITE_API_PORT}/sanctum/csrf-cookie`, 'string').then(async response => {
                    await axios.post('/Login', credentials).then(async (data) => {
                        if (data === undefined) throw new Error('Connexion impossible');
                        await this.setUserData(data);
                        this.initRedisService(data);
                        rememberStore.removePage("lastApprovalSaved");
                    });
                });

                return true;
            } catch (err) {
                return err;
            }
        },
        initRedisService(user) {
            // Initialize RedisService
            this.redisService = new RedisService(user.id);

            this.redisService.socket.emit('userConnected', { user: user, route: window.location.pathname });
            this.redisService.listenForKeyUpdates("DisconnectUser", this.handleDisconnectUsers);

            // Setup beforeunload event
            window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
        },

        handleBeforeUnload() {
            if (this.redisService) {
                this.redisService.disconnect();
                this.redisService = null; // Clean up RedisService instance
            }
        },

        handleDisconnectUsers(value) {
            if (value !== null && (this.userInfo?.user.is_dev ||
                (value !== "All" && parseInt(value) !== this.userInfo?.user.id))) { return; }

            this.logout().then(() => {
                router.push({name: 'Login'});
            });
        },

        async logout () {
            window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this));
            this.resetInactivityTimer(true);
            const permissionStore = usePermissionStore();
            await axios.get('/Logout', 'string').then(() => {
                this.resetInactivityTimer(true);
                this.stopMaintenanceInterval();
                this.$reset();
                permissionStore.clear();
            })
            return true;
        },

        async setUserData (userData) {
            if (this.isMaintenance && !userData.user.is_dev) { this.logout(); return; }

            const permissionStore = usePermissionStore();

            this.userInfo = userData;
            this.setTokenInfo(userData.token);

            await permissionStore.getUserPermissions(userData.user.id);
        },

        setTokenInfo (token) {
            axios.setTokenInfo(token);
        },

        async getMaintenance(isForce = false) {
            if (isForce || Object.keys(this.maintenance).length === 0) {
                await axios.get('/MaintenanceCurrent').then((response) => {
                    this.maintenance = response;
                });
            }

            if (isForce || Object.keys(this.maintenanceToday).length === 0) {
                await axios.get('/MaintenanceToday').then((response) => {
                    this.maintenanceToday = response;
                });
            }
        },
        
        sendMaintenanceMemo() {
            axios.get('/MaintenanceMemo').then(() => {
                
            });
        },

        stopMaintenanceInterval() {
            clearInterval(this.maintenanceInterval);
            this.maintenanceInterval = null;
        },

        updateCurrentRoute(route) {  // Add this action
            this.currentRoute = route; 
            if (this.redisService) {
                this.redisService.handleRouteChange(this.userInfo, route);
            } else {
                this.initRedisService(this.userInfo);
            }

            this.resetInactivityTimer();
        },

        setInactivityTimer(timeout, warningTimeout) {
            this.timeout = timeout * 60;
            this.warningTimeout = warningTimeout * 60;
        },

        resetInactivityTimer(stopTimer = false) {
            this.showWarningPopup = false;

            if(this.inactivityTimer) {
                clearTimeout(this.inactivityTimer);
                this.inactivityTimer = null
            }
            if(this.warningTimer) {
                clearTimeout(this.warningTimer);
                this.warningTimer = null
            }

            if(!stopTimer) {
                this.inactivityTimer = setTimeout(this.handleTimeout, this.timeout * 1000);
                this.warningTimer = setTimeout(this.showInactivityWarning, (this.timeout - this.warningTimeout) * 1000);
            }
        },

        showInactivityWarning() {
            this.showWarningPopup = true;
        },

        handleTimeout() {
            this.logout().then(() => {
                router.push({name: 'Login', query: { sessionExpired: '1' }});
            });
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['userInfo', 'maintenance', 'maintenanceToday', 'isThrottled', 'currentRoute', 'redisService', 'showWarningPopup', 'timeout', 'warningTimeout'],
    },
})