<style scoped lang="scss">
#navbar {
    padding: 0 .7em;
    background: rgb(103, 103, 103);
    background: linear-gradient(180deg, rgba(103, 103, 103, 1) 0%, rgba(14, 14, 14, 1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

#navbar i {
    font-size: 21px;
}

.icon-navbar {
    text-decoration: none !important;
    padding: 22px 10px 5px 10px;
}

.logo {
    background: url("@/assets/Logo_Gesware_V2_white.png") no-repeat;
    background-size: 40px auto;
    display: inline-block;
    padding-left: 50px;
    margin-right: 2em;
    text-decoration: none;
    background-position-y: bottom;
}

#navbar .navbar-selected:not(.dropdown-item) {
    background: linear-gradient(to bottom, #525657 0%,#000000 85%)!important;
}

.website-info {
    padding-top: .5em !important;
}

.website-info p {
    font-size: 14px;
    margin: 0;
}

.gesware-version {
    font-size: small;
    font-weight: bold;
    text-align: center;
}

.gesware-version p {
    font-size: x-small;
    font-weight: bold;
}
.dropdown-toggle {
    padding: 0;
    margin-bottom: .3em;
}
.span-text {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
}

img {
    vertical-align: unset;
    display: inline-block;
    height: auto;
}

.dropdown .btn {
    border-color: transparent;
}
.dropdown-item > i {
    font-size: unset !important;
}

.dropdown-menu {
    background-color: #3A3A3A;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 9999;
}
.dropdown-item {
    color: #ffffff !important;
    background-color: #3A3A3A !important;
}
.dropdown-item:hover {
    background-color: #6A6A6A !important;
}
.fa-stack-1x {
    font-size: 16px !important;
    left: 5px !important;
}
.fa-stack {
    width: 2em !important;
    vertical-align: sub !important;
}
.badge {
    font-size: 9px;
    margin-right: -5px;
    margin-left: 4px;
    margin-top: 2px;
    vertical-align: text-top;
}
.hide-config {
    visibility: hidden;
}

.filter-white {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(175%) contrast(100%);
}

.project-icon {
    @extend .filter-white;
    width: 27px;
    height: auto;
    display: inline;
}
.icon-wrapper {
    width: 27px;
    text-align: center;
    margin-left: -3px;
}
.icon-wrapper i {
    font-size: 16px !important;
}
.text-be {
    position: absolute;
    top: -4px;
    left: 7px;
    font-size: 12px;
}
.report-dropdown-item .dropdown-item {
    display: flex;
}
.report-dropdown-item .dropdown-item div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>


<template>
    <div>
        <div id="navbar">
            <div>
                <router-link to="/" class="logo">
                    <div class="text-white website-info">
                        <div class="gesware-version">
                            Ge$ware
                            <p :class="{'text-green': !IS_PROD}" >{{ APP_VERSION }}</p>
                        </div>
                    </div>
                </router-link>

                <router-link to="/home" class="icon-general icon-navbar" style="display: inline-block;"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Accueil" data-bs-trigger="hover">
                    <i class="fa-solid fa-house-chimney "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Cie List'}" v-if="hasPermission('Cie List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Compagnies" data-bs-trigger="hover">
                    <i class="fa-solid fa-building "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Contact Book'}" v-if="hasPermission('Contact Book')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Contacts" data-bs-trigger="hover">
                    <i class="fa-solid fa-address-book "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Work Report'}" v-if="hasPermission('Work Report')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Rapports Travail" data-bs-trigger="hover">
                    <div class="span-text">RT</div>
                    <div class="unread-count badge rounded-pill bg-general text-general" id="badge-rt" v-if="useBadgeStore.badgeCount.rt > 0">
                        {{ useBadgeStore.badgeCount.rt }}
                    </div>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Approval List'}" v-if="hasPermission('Approval List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Liste d'Acceptation" data-bs-trigger="hover">
                    <i class="fa-solid fa-list-check icon-operation fa-xs"></i>
                    <div class="unread-count badge rounded-pill bg-operation text-general" v-if="useBadgeStore.badgeCount.at > 0">
                        {{ useBadgeStore.badgeCount.at }}
                    </div>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Project List'}" v-if="hasPermission('Project List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Projets" data-bs-trigger="hover">
                    <div style="display: inline-block;">
                        <img style="width: 29px;" src="@/assets/project.svg" alt="" />
                    </div>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Material List'}" v-if="hasPermission('Material List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Matériel" data-bs-trigger="hover">
                    <i class="fa-solid fa-cart-plus icon-operation "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Material Historic'}" v-if="hasPermission('Material Historic')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Historique Matériel" data-bs-trigger="hover">
                    <i class="fa-solid fa-cart-shopping icon-operation "></i>
                </router-link>
                <template v-if="hasPermission('Part List') || hasPermission('Brand List') || hasPermission('Category List')">
                    <a class="icon-general icon-navbar dropdown" href="#" >
                        <button class="btn dropdown-toggle icon-operation" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                            <i class="fa-solid fa-barcode icon-operation"
                                v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Composantes" data-bs-trigger="hover">
                            </i>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link class="dropdown-item" :to="{name: 'Part List'}" v-if="hasPermission('Part List')">
                                    Pièces
                                </router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{name: 'Brand List'}" v-if="hasPermission('Brand List')">
                                    Marques
                                </router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{name: 'Category List'}" v-if="hasPermission('Category List')">
                                    Catégories
                                </router-link>
                            </li>
                        </ul>
                    </a>
                </template>
                <router-link class="icon-general icon-navbar" :to="{name: 'Inventory'}" v-if="hasPermission('Inventory')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Inventaires" data-bs-trigger="hover">
                    <div style="display: inline-block; vertical-align: middle;">
                        <img style="width: 22px;" src="@/assets/shelf.svg" class="inventory-blue" alt="" />
                    </div>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Supplier Price List'}" v-if="hasPermission('Supplier Price List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Prix fournisseurs" data-bs-trigger="hover">
                    <span class="span-text icon-operation">PF</span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Shipping List'}" v-if="hasPermission('Shipping List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Livraisons" data-bs-trigger="hover">
                    <i class="fa-solid fa-truck icon-operation  fa-flip-horizontal"></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Shipping Returns List'}" v-if="hasPermission('Shipping Returns List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Retour livraisons" data-bs-trigger="hover">
                    <span class="fa-stack">
                        <i class="fa-solid fa-truck icon-operation fa-2xs fa-flip-horizontal"></i>
                        <i class="fa-solid fa-reply fa-stack-1x text-red-600 fa-2xs"></i>
                    </span>
                    <span class="unread-count badge rounded-pill bg-operation text-general" id="badge-shippingReturn" 
                        v-if="usePermissionStore.canAccess('can_view_expedition') && useBadgeStore.badgeCount.shippingReturn > 0">
                        {{ useBadgeStore.badgeCount.shippingReturn }}
                    </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Order List BE'}" v-if="hasPermission('Order List BE')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Bons Externes" data-bs-trigger="hover">
                    <span class="position-relative">
                        <i class="fa-solid fa-cart-shopping icon-operation" style="position: relative"></i>
                        <span class="text-be">BE</span>
                    </span>
                    <span class="unread-count badge rounded-pill bg-operation text-general-pale" id="badge-orderBE"
                        v-if="usePermissionStore.canAccess('is_purchasing_manager') && useBadgeStore.badgeCount.ordersBE > 0">
                        {{ useBadgeStore.badgeCount.ordersBE }}
                    </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Order List'}" v-if="hasPermission('Order List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Achats" data-bs-trigger="hover">
                    <i class="fa-solid fa-cart-shopping icon-supplying"></i>
                    <span class="unread-count badge rounded-pill bg-supplying text-general-pale" id="badge-order"
                        v-if="usePermissionStore.canAccess('is_purchasing_manager') && useBadgeStore.badgeCount.orders > 0">
                        {{ useBadgeStore.badgeCount.orders }}
                    </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Order Returns List'}" v-if="hasPermission('Order Returns List')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Retour" data-bs-trigger="hover">
                    <span class="fa-stack">
                    <i class="fa-solid fa-cart-shopping icon-supplying fa-2xs"></i>
                    <i class="fa-solid fa-reply fa-8xs fa-stack-1x text-red-600 fa-2xs"></i>
                </span>
                <span class="unread-count badge rounded-pill bg-supplying text-general-pale" 
                    v-if="usePermissionStore.canAccess('is_purchasing_manager') && useBadgeStore.badgeCount.ordersReturn > 0">
                    {{ useBadgeStore.badgeCount.ordersReturn }}
                </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Bill'}" v-if="hasPermission('Bill')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Factures" data-bs-trigger="hover">
                    <i class="fa-solid fa-dollar-sign icon-administration"></i>
                    <span class="unread-count badge rounded-pill bg-administration text-general " 
                        v-if="usePermissionStore.canAccess('is_billing_manager') && useBadgeStore.badgeCount.billMf > 0">
                        {{ useBadgeStore.badgeCount.billMf }}
                    </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Pay'}" v-if="hasPermission('Pay')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Paies" data-bs-trigger="hover">
                    <i class="fa-solid fa-file-lines icon-administration "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Expense'}" v-if="hasPermission('Expense')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Dépenses" data-bs-trigger="hover">
                    <i class="fa-regular fa-money-bill-1 icon-administration "></i>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Credit'}" v-if="hasPermission('Credit')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Comptes Crédits" data-bs-trigger="hover">
                    <i class="fa-solid fa-credit-card icon-administration "></i>
                </router-link>
                <a href="#" class="icon-general icon-navbar dropdown" v-if="usePermissionStore.canAccess('can_view_reports')">
                    <button class="btn dropdown-toggle icon-administration" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fa-solid fa-chart-column icon-administration"
                            v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Rapports" data-bs-trigger="hover">
                        </i>
                    </button>
                    <ul class="dropdown-menu report-dropdown">
                        <li class="report-dropdown-item" v-for="report in orderedReports">
                            <router-link :to="{name: report.name}" class="dropdown-item" v-if="hasPermission(report.name)">
                                <div class="icon-wrapper">
                                    <img v-if="report.isImgProject" class="align-middle" :class="report.classes" src="@/assets/project.svg" alt="">
                                    <i class="align-middle" :class="report.classes" v-else></i>
                                </div>
                                <div style="padding-left: 5px;">{{ report.title }}</div>
                            </router-link>
                        </li>
                    </ul>
                </a>
            </div>
            <div>
                <UpdateCountdown />
            </div>
            <div>
                <template v-if="useAuthUserStore.isUserDev">
                    <a href="#" class="icon-general icon-navbar dropdown">
                        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                            <i class="fa-solid fa-user-secret" v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="DevTools" data-bs-trigger="hover"></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <router-link to="/dev" class="dropdown-item">
                                    Rapport DEP / ALL
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/UpdateReportsAmounts" class="dropdown-item">
                                    Update Totaux Rapports
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/UpdateApprovalListHourApproved" class="dropdown-item">
                                    Update Heures Total Rapports
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/UpdateExpenseTotal" class="dropdown-item">
                                    Update Totaux Dépenses
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/ArchiveProjects" class="dropdown-item">
                                    Archive Projects
                                </router-link>
                            </li>
                        </ul>
                    </a>
                </template>
                <router-link class="icon-general icon-navbar" :to="{name: 'Dashboard'}" v-if="hasPermission('Dashboard')"
                            v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Dashboard" data-bs-trigger="hover">
                    <i class="fa-solid fa-gauge-high icon-general"></i>
                    <span class="unread-count badge rounded-pill bg-danger"
                        v-if="usePermissionStore.canAccess('can_view_dashboard') && useBadgeStore.badgeCount.dashboard > 0">
                        {{ useBadgeStore.badgeCount.dashboard }}
                    </span>
                </router-link>
                <router-link class="icon-general icon-navbar" :to="{name: 'Employe'}" v-if="hasPermission('Employe')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Listes employés" data-bs-trigger="hover">
                    <i class="fa-solid fa-users "></i>
                </router-link>
                <a href="#" class="icon-general icon-navbar" @click="openOrderPopup()" v-if="usePermissionStore.canAccess('can_view_cmd_popup')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Commander" data-bs-trigger="hover">
                    <i class="fa-solid fa-industry"></i>
                    <span class="unread-count badge rounded-pill bg-supplying" 
                        v-if="usePermissionStore.canAccess('can_view_cmd_popup') && useBadgeStore.badgeCount.orderPopup > 0">
                        {{ useBadgeStore.badgeCount.orderPopup }}
                    </span>
                </a>
                <router-link class="icon-general icon-navbar" :to="{name: 'Billing Memo'}" v-if="hasPermission('Billing Memo')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Mémos facturation" data-bs-trigger="hover">
                    <i class="fa-solid fa-dollar-sign "></i>
                </router-link>
                <div style="display: inline-block;">
                    <template v-if="usePermissionStore.canAccess('can_view_memos')">
                        <Memo />
                    </template>
                </div>
                <a href="#" class="icon-general icon-navbar" @click="openPasswordPopup()" v-if="hasPermission('Password')"
                    v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Modifier mot de passe" data-bs-trigger="hover">
                    <i class="fa-solid fa-key  fa-rotate-270"></i>
                </a>
                <router-link class="icon-general icon-navbar" :to="{name: 'Documentation'}" v-if="hasPermission('Documentation')"
                            v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Documentation" data-bs-trigger="hover">
                    <i class="fa-solid fa-book"></i>
                </router-link>
                <a href="#" class="icon-general icon-navbar dropdown">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" >
                        <i class="fa-solid fa-gear " v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Configuration" data-bs-trigger="hover"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li v-if="usePermissionStore.isUserInViewMode">
                            <a href="javascript:void(0)" class="dropdown-item" @click.prevent="usePermissionStore.resetPermissions()">
                                Sortir du mode permissions
                            </a>
                        </li>
                        <li>
                            <router-link class="dropdown-item" :to="{name: 'General Params'}" v-if="hasPermission('General Params')">
                                <i class="fa-solid fa-cubes "></i>
                                Paramètres Généraux
                            </router-link>
                        </li>
                        <li class="dropdown-item" @click="openPersonalSettings()">
                            <i class="fa-solid fa-gear"></i>
                            Paramètres Personnels
                        </li>
                        <li>
                            <router-link class="dropdown-item" :to="{name: 'Role'}" v-if="hasPermission('Role')">
                                <i class="fa-solid fa-user-shield "></i>
                                Rôles
                            </router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" :to="{name: 'Annual Rate'}" v-if="hasPermission('Annual Rate')">
                                <i class="fa-solid fa-percent "></i>
                                Taux Annuels
                            </router-link>
                        </li>
                    </ul>
                </a>
                <button class="icon-general icon-navbar" :style="{'margin-left': !hasPermission('Password') ? '6em' : '3em'}" @click="logout()">
                    <i class="fa-solid fa-power-off " v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Déconnecter" data-bs-trigger="hover"></i>
                </button>
            </div>
        </div>
        <OrderPopup ref="orderPopup"></OrderPopup>
        <PasswordReset ref="passwordReset"></PasswordReset>
        <PersonalSettings ref="personalSettings"></PersonalSettings>
    </div>
</template>

<script>
import { useAuthUserStore } from "@/store/auth.js";
import { usePermissionStore } from '@/store/perms.js';
import { useBadgeStore } from "@/store/badge";
import OrderPopup from "@/components/order/OrderPopup.vue";
import PasswordReset from "@/components/login/PasswordReset.vue";
import Memo from "@/components/navbar/memo/Memo.vue";
import PersonalSettings from "@/components/config/PersonalSettings.vue";
import UpdateCountdown from "@/components/navbar/UpdateCountdown.vue";

export default {
    components: {PersonalSettings, Memo, OrderPopup, PasswordReset, UpdateCountdown},
    data() {
        return {
            useAuthUserStore: useAuthUserStore(),
            usePermissionStore: usePermissionStore(),
            useBadgeStore: useBadgeStore(),
            reports: [
                {name: 'Billing Report', title: 'Facturation', classes: 'fa-solid fa-dollar'},
                {name: 'Worked Hours Report', title: 'Heures Travaillées', classes: 'fa-solid fa-clock'},
                {name: 'Occupation Report', title: 'Occupation', classes: 'fa-solid fa-pie-chart'},
                {name: 'Receivable Report', title: 'Recevables', classes: 'fa-solid fa-inbox'},
                {name: 'Parts Monitoring List', title: 'Suivi Pièces', classes: 'fa-solid fa-exchange'},
                {name: 'Suivi Po', title: 'Suivi PO', classes: 'fa-solid fa-dollar-sign'},
                {name: 'Current Projects Report', title: 'Projets Courants', classes: 'filter-white project-icon', isImgProject: true},
                {name: 'Tec List', title: 'TEC', classes: 'fa-solid fa-bars'},
                {name: 'TEC Hours List', title: 'TEC_Heures', classes: 'fa-solid fa-clock'},
                {name: 'Sales', title: 'Ventes', classes: 'fa-solid fa-dollar-sign'},
            ],
        }
    },
    computed: {
        APP_VERSION() {
            return `v5 (${import.meta.env.VITE_APP_VERSION})`;
        },
        IS_PROD() {
            return import.meta.env.VITE_APP_ENV === "laravel";
        },
        orderedReports() {
            return this.$general.orderByCustom(this.reports, 'title', true);
        },
    },
    methods: {
        logout () {
            this.useAuthUserStore.logout().then(() => {
                this.$router.push({name: 'Login'});
            });
        },
        openOrderPopup() {
            this.$refs.orderPopup.open();
        },
        openPasswordPopup() {
            this.$refs.passwordReset.open();
        },
        hasPermission(routeLink) {
            const route = this.$router.options.routes.find(route => route.name === routeLink);
            const metadata = route && route.meta;
            return !(metadata.permission_requirement !== undefined && !this.usePermissionStore.canAccess(metadata.permission_requirement))
        },
        openPersonalSettings() {
            this.$refs.personalSettings.open();
        },
    }
}
</script>