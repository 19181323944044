<style scoped>
    .app-container {
        /* height: 100vh; */
        padding-top: 7px;
        padding-bottom: 30px;
    }
    .permission-mode {
        position: fixed;
        bottom: 10px;
        right: 10px;
        background-color: #FFC107;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 11px;
        text-align: center;;
    }
    #exit-permission {
        font-weight: 700;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        white-space: nowrap;
    }
    .permission-mode:hover #exit-permission {
        visibility: visible;
    }
    .permission-mode:hover #show-permission {
        visibility: hidden;
    }
</style>

<template>
    <div>
        <Navbar v-if="!$route.meta.hideNavbar && $route.name !== undefined" />
        <div class="app-container">
            <router-view />

            <div class="permission-mode hand-cursor" v-if="showPermissionMode" @click="this.$store.permissionStore.resetPermissions()">
                <span id="show-permission">Mode permissions : <strong>[ {{ this.$store.permissionStore.getViewAsRole }} ]</strong></span>
                <span id="exit-permission">Sortir du mode permissions</span>
            </div>
            <PDFViewer ref="pdfViewer"></PDFViewer>
        </div>
        <ToastComponent ref="toastApp" :is-bottom="true"></ToastComponent>
        <ConfirmDialog ref="confirmTimeoutDialog" :is-save-valid="true"></ConfirmDialog>
    </div>
</template>

<script>
import Navbar from "@/components/navbar/Navbar.vue";
import { useAuthUserStore } from "@/store/auth.js";
import { usePermissionStore } from "@/store/perms.js";
import { useConstantStore } from "@/store/constants.js";
import { useRememberStore } from "@/store/remember.js";
import { useMemoStore } from "@/store/memo.js";
import { useBadgeStore } from "@/store/badge";
import { useEnumsStore } from "@/store/enums";
import ToastComponent from "@/components/_shared/ToastComponent.vue";
import ConfirmDialog from "@/components/_shared/ConfirmDialog.vue";
import PDFViewer from "@/components/_shared/PDFViewer.vue";

export default {
    name: "Login",
    components: {
        PDFViewer,
        ToastComponent,
        Navbar,
        ConfirmDialog
    },
    computed: {
        showPermissionMode() {
            return !this.$route.meta.hideNavbar && this.$route.name !== undefined && this.$store.permissionStore?.isUserInViewMode;
        }
    },
    mounted() {
        this.$store.authUserStore = useAuthUserStore();
        this.$store.permissionStore = usePermissionStore();
        this.$store.constantStore = useConstantStore();
        this.$store.rememberStore = useRememberStore();
        this.$store.memoStore = useMemoStore();
        this.$store.badgeStore = useBadgeStore();
        this.$store.enumsStore = useEnumsStore();

        this.$store.pdfviewer = this.$refs.pdfViewer;

        this.$store.authUserStore.getMaintenance(true);
        
        if (this.$store.authUserStore.isLoggedIn) {
            this.$store.authUserStore.setTokenInfo(this.$store.authUserStore.userInfo.token);
            this.$store.permissionStore.getPermissions();
            this.$store.constantStore.getConstants();
            this.$store.constantStore.getHistoryRateKm();
        }
        
        this.$store.authUserStore.$subscribe((mutation, state) => {
            if (state.isThrottled) {
                this.$refs.toastApp.setEverything(
                    'Erreur',
                    '1 minute',
                    'Vous avez atteint la limite de requêtes.\nVeuillez attendre une minute avant de continuer.',
                    'error'
                );
                this.$refs.toastApp.show();
            }

            if(state.showWarningPopup) {
                this.showWarningDialog();
            }
        });
    },
    methods: {
        async showWarningDialog() {
            const confirm = await this.$refs.confirmTimeoutDialog.show({
                title: 'Gesware',
                message: 'Votre session va bientôt expirer, voulez-vous la prolonger?',
                showCancel: false,
                isConfirmIconCheck: true
            });

            if(confirm === true) {
                this.$store.authUserStore.resetInactivityTimer();
            }
        },
        showMaintenancePopup(minutesLeft) {
            this.$refs.toastApp.setEverything(
                'Mise à jour Gesware',
                `dans ${minutesLeft} minute${(minutesLeft > 1) ? 's' :''}`,
                'Vous serez bientôt déconnecté automatiquement.',
                'info',
                minutesLeft > 5
            );
            this.$refs.toastApp.show();
        }
    }
}
</script>